import client, { IDataResponse } from '../request'
import { AppClient } from './appClient'

export interface ProductVariant {
  id: number
  custom_id: string
  name: string
  fullname: string
  product_id: number
  sku: string
  image_url: string
  price: number
  currency: string
  barcode: string
  created_at: Date
  updated_at: Date
}

export interface ProductLinkCreateState {
  app_client_id: string
  product_id: string | number
}

export interface ProductLink {
  id: number
  product_id: number
  linked_product_id: number
  created_at: Date
  updated_at: Date
  linked_product: Product
  product: Product
}

export interface Product {
  id: number
  custom_id: string
  name: string
  description: string
  page_url: string
  image_url: string
  variants?: ProductVariant[]
  app_client?: AppClient
  cross_store_links?: ProductLink[]
  created_at: Date
  updated_at: Date
}

const getAll = (appClientId: string) => {
  return `/admin/app-clients/${appClientId}/products`
}

const get = (appClientId: string, id: string | number) => {
  return `/admin/app-clients/${appClientId}/products/${id}`
}

const getLinkedProducts = (appClientId: string, id: string | number) => {
  return `/admin/app-clients/${appClientId}/products/${id}/links`
}

const deleteLinkedProduct = (
  appClientId: string,
  productId: string | number,
  linkId: string | number
) => {
  return client.delete(
    `/admin/app-clients/${appClientId}/products/${productId}/links/${linkId}`
  )
}

const addLinkedProduct = (
  appClientId: string,
  productId: string | number,
  data: ProductLinkCreateState
) => {
  return client.post(
    `/admin/app-clients/${appClientId}/products/${productId}/links`,
    data
  )
}

const toRow = (data: IDataResponse<Product>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const productLinkToRow = (data: IDataResponse<ProductLink>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<Product>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const ProductService = {
  getAll,
  get,
  getLinkedProducts,
  deleteLinkedProduct,
  addLinkedProduct,
  productLinkToRow,
  toRow,
  toPaginate,
}

export default ProductService
