import { useState } from 'react'
import { Table, Empty, Spin, TableColumnsType } from 'antd'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'
import { IDataResponse, serialize } from '../../network/request'
import appClientStore from '../../lib/store/appClient'
import StatisticsService, {
  AverageRatingStat,
} from '../../network/services/statistics'
import { useTranslation } from 'react-i18next'

const AverageRatingTable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sort, setSort] = useState<string | null>('avg_rating:desc')

  const { data: response, isLoading } = useSWR<
    IDataResponse<AverageRatingStat>
  >(
    serialize(StatisticsService.getAll(appClientId!), {
      page: page,
      limit: limit,
      sort: sort ?? 'avg_rating:desc',
    })
  )

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: (keySelected: any[]) => {
  //     setSelectedRowKeys(keySelected)
  //   },
  // }

  const columns: TableColumnsType<AverageRatingStat> = [
    {
      title: t('productName'),
      render: (record: AverageRatingStat) => record.product.name,
      key: 'products.name',
      sorter: true,
    },
    {
      title: t('totalReviews'),
      dataIndex: 'total_reviews',
      key: 'total_reviews',
      sorter: true,
    },
    {
      title: t('averageRatings'),
      render: (record: AverageRatingStat) =>
        parseFloat(record.avg_rating).toFixed(2),
      key: 'avg_rating',
      sorter: true,
    },
  ]

  if (isLoading) {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        <Spin />
      </div>
    )
  }

  return (
    <>
      {response?.data && response.data.length > 0 ? (
        <Table
          columns={columns}
          dataSource={response.data}
          onChange={(pagination, __, sorter: any) => {
            setSort(
              sorter != null &&
                !_.isEmpty(sorter?.columnKey) &&
                !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'avg_rating'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null
            )

            setPage(pagination.current ?? 1)
            setLimit(pagination.pageSize ?? 10)
          }}
          pagination={{
            showSizeChanger: true,
            total: response.meta?.total,
            pageSize: limit,
            current: page,
            pageSizeOptions: [10, 20, 50, 100, 200, 300],
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`${record.product.id}`)
              }, // click row
            }
          }}
          // rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default AverageRatingTable
