import { FC, useEffect, useState } from 'react'
import { Table, Empty, Row, Col, Space, Tag } from 'antd'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/product_table'
import TableFilter, { ITableFilter } from '../../components/TableFilter'
import { serialize } from '../../network/request'
import Gaps from '../../components/Gaps'
import appClientStore from '../../lib/store/appClient'
import { green, red } from '@ant-design/colors'
import ProductService, { Product } from '../../network/services/product'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/es/table'

const ProductTable: FC<{ total: number; overridePage?: number }> = ({
  total,
  overridePage,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState<any>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([])

  const { data: response } = useSWR(
    serialize(ProductService.getAll(appClientId!), {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters,
    })
  )
  const rows = ProductService.toRow(response)

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: (keySelected: any[]) => {
  //     setSelectedRowKeys(keySelected)
  //   },
  // }

  const columns: ColumnsType<Product> = [
    {
      title: t('shopifyId'),
      dataIndex: 'custom_id',
      key: 'custom_id',
      sorter: true,
    },
    {
      title: t('productName'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: t('linkedProductReviews'),
      render: (_, record) => (
        <Space direction='vertical'>
          {record.cross_store_links &&
            record.cross_store_links.map((item) => (
              <Tag>
                {item.linked_product.name} (
                {item.linked_product.app_client?.name})
              </Tag>
            ))}
        </Space>
      ),
      key: 'cross_store_links',
      sorter: true,
    },
  ]

  // content={
  //   initialValue.variants.length > 0
  //     ? initialValue.variants.map((item) => item.name).join(',')
  //     : '-'
  // }

  const filterColumns: ITableFilter[] = [{ key: 'name', name: 'Name' }]
  filterColumns.forEach((item) => {
    if (
      tableStore.state?.filters &&
      Object.keys(tableStore.state.filters).includes(item.key)
    ) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const batchDeleteRows = async () => {
    // try {
    //   for (const id of selectedRowKeys) {
    //     const { data } = await ReviewService.remove(id)
    //     if (data?.success === true) {
    //       message.success(`Delete source success`)
    //     } else {
    //       message.error(`Delete source ${id} failed`)
    //     }
    //     tableStore.state.refresh = Math.random()
    //   }
    //   setVisible(false)
    // } catch (e) {
    //   message.error({ content: e.message, className: 'message-error' })
    // }
  }

  return (
    <>
      <Row>
        <Col span={12}></Col>
        <Col span={12}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            setCurrentFilters={setCurrentFilters}
          />
          <Gaps level={3} />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter: any) => {
            tableStore.state.sort =
              sorter != null &&
              !_.isEmpty(sorter?.columnKey) &&
              !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current ?? 1
            tableStore.state.pageSize = pagination.pageSize ?? 10
          }}
          pagination={{
            showSizeChanger: true,
            total,
            pageSize: state.pageSize,
            current: state.currentPage,
            pageSizeOptions: [10, 20, 50, 100, 200, 300],
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter(
                      [...selectedRowKeys],
                      function (o) {
                        return o !== record.id
                      }
                    )
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              }, // click row
            }
          }}
          // rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default ProductTable
