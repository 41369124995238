import client, { IDataResponse } from '../request'
import { Product } from './product'
import { Review } from './reviews'

export enum ReviewRequestStatus {
  SCHEDULED = 'scheduled',
  SENT = 'sent',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
  REVIEWED = 'reviewed',
}
export interface ReviewRequest {
  id: number
  external_order_id: string
  external_order_name: string
  product_id: number
  email: string
  sent_at?: string
  expires_at?: string
  created_at: string
  updated_at: string
  status: ReviewRequestStatus
  product: Product
  review?: Review
}

export const reviewRequestColors: Record<ReviewRequestStatus, string> = {
  sent: 'processing',
  reviewed: 'success',
  failed: 'error',
  cancelled: 'default',
  scheduled: 'warning',
}

const getAll = (appClientId: string) => {
  return `/admin/app-clients/${appClientId}/review-requests`
}

const get = (appClientId: string, id: string | number) => {
  return `/admin/app-clients/${appClientId}/review-requests/${id}`
}

const publicGet = (id: string | number, token: string) => {
  return `/public/review-requests/${id}?token=${token}`
}

const remove = (appClientId: string, id: string | number) => {
  return client.delete(
    `/admin/app-clients/${appClientId}/review-requests/${id}`
  )
}

const manualSend = (appClientId: string, id: string | number) => {
  return client.post(
    `/admin/app-clients/${appClientId}/review-requests/${id}/manual-send`
  )
}

const toRow = (data: IDataResponse<ReviewRequest>) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id,
      }
    })
  }

  return []
}

const toPaginate = (data: IDataResponse<ReviewRequest>) => {
  return {
    total: data?.meta?.total ?? 0,
  }
}

const ReviewRequestService = {
  getAll,
  get,
  remove,
  manualSend,
  publicGet,
  toRow,
  toPaginate,
}

export default ReviewRequestService
