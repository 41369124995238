const JaTranslation: Record<string, string> = {
  reviews: 'レビュー',
  requests: 'リクエスト',
  staff: 'スタッフ',
  products: '商品',
  settings: '設定',
  english: '英語',
  japanese: '日本語',
  chineseTw: '中国語（繁体字）',
  chineseCn: '中国語（簡体字）',
  korean: '韓国語',

  //reviews
  title: 'タイトル',
  content: '内容',
  name: '名前',
  email: 'メール',
  product: '商品',
  ratings: '評価',
  verified: '認証済み',
  createdAt: '作成日付',
  publication: '公開',
  publish: '公開する',
  unpublish: '不公開にする',
  notVerified: '未認証',
  orderId: '注文ID',

  //product
  productName: '商品名',
  shopifyId: 'Shopify ID',
  linkedProductReviews: 'レビュー連携',

  //search
  removeFilters: 'フィルターを削除する',
  search: '検索する',
  searchScope: '検索範囲',
  chooseScope: '検索範囲を選んでください',

  //review request
  delete: 'データ削除',
  deleteNumberRequest: 'リクエスト（{{number}}件）を削除しますか？',
  deletedNotReversible: '削除されたデータは元に戻れません',
  orderNumber: '注文番号',
  status: '状態',
  sentAt: 'メール送信日付',
  reviewedAt: 'レビュー作成日付',
  manuallySendEmailTo: '手動で{{email}}にレビューメールを送信しますか?',
  sendEmailManually: '手動送信',
  sendEmail: '送信する',
  successfullySentEmailTo: '{{email}}にレビューメールを送信しました',
  failedToSentEmailTo: '{{email}}にレビューメール送信できませんでした',

  //dashboard
  dashboard: 'ダッシュボード',
  reviewRatingTopNumber: '平均評価ランキングトップ{{value}}',
  averageRatings: '平均評価',
  totalReviews: '評価総数',

  //general
  cancel: 'キャンセル',
  confirmation: 'ご確認',
  confirm: '確認',
  confirmDelete: '削除する',
  deletedCount: 'データ（{{number}}件）が削除されました',
  deleteFail: '削除が失敗しました',
  moreActions: '行動',
}

export default JaTranslation
