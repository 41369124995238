import { message, Modal, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import ReviewRequestService, {
  ReviewRequest,
} from '../../network/services/reviewRequests'
import { useSnapshot } from 'valtio'
import appClientStore from '../../lib/store/appClient'
import { useState } from 'react'

const ManualSendModal = ({
  isOpen,
  onClose,
  reviewRequest,
  refresh,
}: {
  isOpen: boolean
  onClose: () => void
  reviewRequest: ReviewRequest | null
  refresh: () => void
}) => {
  const { t } = useTranslation()
  const {
    state: { id: appClientId },
  } = useSnapshot(appClientStore)
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    if (!reviewRequest) {
      return
    }

    if (isLoading) {
      return
    }

    setIsLoading(true)

    try {
      const { data: result } = await ReviewRequestService.manualSend(
        appClientId!,
        reviewRequest.id
      )
      if (result.success) {
        message.success(
          t('successfullySentEmailTo', { email: reviewRequest?.email ?? '' })
        )
        refresh()
        onClose()
      }
    } catch (e: any) {
      message.error({
        content:
          t('failedToSentEmailTo', {
            email: reviewRequest?.email ?? '',
          }) +
          ' ' +
          e.message,
        className: 'message-error',
      })
    }

    setIsLoading(false)
  }

  return (
    <Modal
      open={isOpen}
      onCancel={() => {
        if (isLoading) {
          return
        }

        onClose()
      }}
      title={t('confirmation')}
      okText={t('sendEmail')}
      cancelText={t('cancel')}
      onOk={handleConfirm}
      confirmLoading={isLoading}
      cancelButtonProps={{
        loading: isLoading,
      }}
    >
      <Typography.Text>
        {t('manuallySendEmailTo', { email: reviewRequest?.email ?? '' })}
      </Typography.Text>
    </Modal>
  )
}

export default ManualSendModal
